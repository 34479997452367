import React, { Fragment, useContext } from "react"
import { Link, navigate } from "gatsby"
import classNames from "classnames"

import Message from "elements/Message"
import { ProgramName } from "elements/Brand"
import SendToPatientModal from "./SendToPatientModal"
import UploadGuidelines from "elements/UploadGuidelines"
import UploadPrescriptionSection from "elements/UploadPrescriptionSection"

import { modal, toast } from "../../context/AppReducer"
import { AppContext } from "../../context/AppContext"
import styles from "./utils/eprescription.module.scss"
import { getAuthUser } from "../Auth/services/authUser"
import { sendEprescription } from "./services/eprescription"

const UploadPrescription = () => {
  const { state, dispatch } = useContext(AppContext)
  const { patient, eprescription, documents } = state

  const handleSubmitError = ({ message }) => {
    dispatch({
      type: modal.HIDE_MODAL,
    })
    dispatch({
      type: toast.SHOW_TOAST,
      payload: {
        message: message,
        color: "danger",
      },
    })
  }

  const handleSendToPatient = () => {
    sendEprescription({
      eprescription: {
        ...eprescription,
        patientUid: patient?.id,
        doctorUid: getAuthUser()?.userData?.id,
      },
      doctor: getAuthUser()?.userData,
      patient,
      documents,
      prescriptionFile: documents[0],
      errorCallback: handleSubmitError,
      callback: () => {
        navigate("/complete")
      },
    })
  }

  const handleClick = () => {
    dispatch({
      type: modal.SHOW_MODAL,
      payload: {
        heading: "Send ePrescription",
        isCard: true,
        headerClass: `has-text-primary has-background-light is-size-5`,
        content: (
          <SendToPatientModal handleSendToPatient={handleSendToPatient} />
        ),
      },
    })
  }

  return (
    <Fragment>
      <Message color="warning">
        <p className="has-text-black is-size-6">
          Under the <ProgramName />, each patient will receive one (1)
          Semaglutide 0.25mg Pre-filled Pen and two (2) Semaglutide 0.5mg
          Pre-filled Pens. For prescriptions in excess of this, your patients
          will have to wait until they are commercially available to purchase
          the medicine.
        </p>
      </Message>
      <UploadGuidelines />
      <UploadPrescriptionSection docType={`RX_${patient?.lastName}`} />
      <div className="buttons mt-2 is-centered">
        <button
          onClick={handleClick}
          className={classNames(
            "button is-primary mt-3 is-medium",
            styles["uploadPrescription__submitButton"]
          )}
          disabled={state?.documents?.length < 1}
        >
          Send to Patient
        </button>
      </div>
      <div className="has-text-centered is-size-5">
        <Link
          to="/issue-erx/prescription-details"
          className="button is-text has-text-primary is-medium"
        >
          Back
        </Link>
      </div>
    </Fragment>
  )
}

export default UploadPrescription
