import React from "react"
import { Router } from "@reach/router"

import PrivateRoute from "components/Auth/PrivateRoute"
import UploadPrescription from "components/Eprescription/UploadPrescription"
import SignIn from "components/Auth/SignIn"

export default () => {
  return (
    <Router>
      <PrivateRoute
        path="/upload"
        seoTitle="Upload Prescription"
        component={UploadPrescription}
        title="Upload Prescription"
        subtitle=""
      />
      <SignIn path="/sign-in" />
    </Router>
  )
}
